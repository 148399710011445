import * as React from 'react';
import './GalleryPage.scss';
import {Header} from '../components/header/Header';
import {Footer} from '../components/footer/Footer';
import {graphql, PageProps, useStaticQuery} from 'gatsby';
import {QAllImages} from '../components/QAllImages/QAllImages';
import {Gallery} from '../components/gallery/Gallery';
import {AllImages} from '../components/QAllImages/__generated__/AllImages';
import {PageHead} from '../components/page-head/PageHead';
import {PagesData, PagesData_allGalleriesJson_nodes, PagesData_allGalleriesJson_nodes_images} from './__generated__/PagesData';

type PageContext = {
  id: string;
};

type Props = { pageContext: PageProps & PageContext} & {location: Location};

const GalleryPage = ({pageContext: {id}, location}: Props) => {
  const query: PagesData = useStaticQuery(graphql`
    query PagesData {
      allGalleriesJson {
        nodes {
          id
          galleryName
          images {
            description
            imageName
            width
            height
          }
        }
      }
    }
  `);

  const page: PagesData_allGalleriesJson_nodes | null = query.allGalleriesJson.nodes.find((node) => node.id === id) ?? null;

  if (page === null) {
    return <div>{`Error. Page cannot be created. PageId=${id} is missing.`}</div>;
  }

  const galleryData: PagesData_allGalleriesJson_nodes_images[] = (page.images ?? [])
      .filter((image: PagesData_allGalleriesJson_nodes_images | null) => image !== null) as PagesData_allGalleriesJson_nodes_images[];

  return (
    <>
      <PageHead/>
      <Header />
      <div className="gallery-page-container">
        <QAllImages render={(allImages: AllImages) =>
          <Gallery
            name={page.galleryName ?? 'Missing field "galleryName"'}
            allImages={allImages}
            galleryData={galleryData}
            backPath={location.pathname}
          />}
        />
      </div>
      <Footer />
    </>
  );
};

export default GalleryPage;
