import {GatsbyImage, StaticImage} from 'gatsby-plugin-image';
import './Gallery.scss';
import * as React from 'react';
import {getImageData} from '../../utils/data';
import {ImageProps} from '../QAllImages/QAllImages';
import {Row} from 'react-bootstrap';
import {PagesData_allGalleriesJson_nodes_images} from '../../templates/__generated__/PagesData';
import { Link } from 'gatsby';
import { slugify } from '../../utils/slug';

export type GalleryProps = {
  name: string;
  backPath: string;
  galleryData: PagesData_allGalleriesJson_nodes_images[];
}

export const Gallery: React.FC<ImageProps & GalleryProps> = ({name, allImages, galleryData, backPath}: ImageProps & GalleryProps) => {
  return (
    <>
      <h2 className="text-center">{name}</h2>
      <Row>
        {galleryData.map((galeryImage, index) => (
          <Link to={`/images/${slugify(galeryImage.imageName ?? '')}?back=${encodeURIComponent(backPath)}`} key={index} className="gallery-image col-lg-12">
            <GatsbyImage
              image={getImageData(galeryImage.imageName, allImages)}
              alt={galeryImage.description ?? 'No Description'}
            />
            <div className="bottom-info text-center">
              {galeryImage.description}
            </div>
          </Link>
        ))}
      </Row>
    </>
  );
};
